import { NgModule } from '@angular/core';

import {
	AlertComponent,
	AuthLayoutComponent,
	BaseComponent,
	ButtonCollapseComponent,
	ButtonComponent,
	CardAccessComponent,
	CheckboxComponent,
	CreateLayoutComponent,
	DividerComponent,
	FormGroupComponent,
	IconComponent,
	InputComponent,
	ItemComponent,
	LinkComponent,
	ListDetailComponent,
	SelectComponent,
	StepperProgressComponent,
	TagComponent,
	TextareaComponent,
	TileComponent,
	UploadComponent,
} from '@tecalisdev/infinity';

@NgModule({
	declarations: [],
	imports: [
		AuthLayoutComponent,
		ButtonComponent,
		ButtonCollapseComponent,
		InputComponent,
		LinkComponent,
		DividerComponent,
		IconComponent,
		AlertComponent,
		CheckboxComponent,
		BaseComponent,
		CardAccessComponent,
		TileComponent,
		ItemComponent,
		ListDetailComponent,
		TagComponent,
		StepperProgressComponent,
		CreateLayoutComponent,
		FormGroupComponent,
		SelectComponent,
		TextareaComponent,
		UploadComponent,
	],
	exports: [
		AuthLayoutComponent,
		ButtonComponent,
		ButtonCollapseComponent,
		InputComponent,
		LinkComponent,
		DividerComponent,
		IconComponent,
		AlertComponent,
		CheckboxComponent,
		BaseComponent,
		CardAccessComponent,
		TileComponent,
		ItemComponent,
		ListDetailComponent,
		TagComponent,
		StepperProgressComponent,
		CreateLayoutComponent,
		FormGroupComponent,
		SelectComponent,
		TextareaComponent,
		UploadComponent,
	],
	providers: [],
	bootstrap: [],
})
export class DesignModule {}

import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-registration-request',
	templateUrl: './registration-request.component.html',
	styleUrls: ['./registration-request.component.scss'],
})
export class RegistrationRequestComponent implements OnInit {
	registratonType!: string;
	steps = [
		{ label: 'Datos', selected: true, id: 1 },
		{ label: 'Promoción', selected: false, id: 2 },
		{ label: 'Tienda', selected: false, id: 3 },
	];
	step: number = 1;
	constructor(private readonly location: Location, private readonly cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		const option = Number(this.location.path().split('/')[2]);
		this.registratonType = option === 0 ? 'CANAL ONLINE' : option === 1 ? 'CANAL FÍSICO' : 'CANAL ONLINE Y FÍSICO';
	}

	clickEvent(event: any) {
		this.steps = this.steps.map((item) =>
			item.id === event.id ? { ...item, selected: true } : { ...item, selected: false }
		);
		this.step = this.steps.find((item) => item.selected)!.id;
	}

	buttonAction(option: string) {
		option === 'BACK' ? this.location.back() : null;
	}
}

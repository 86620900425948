import { NgModule } from '@angular/core';

import { DesignModule } from './design.module';

@NgModule({
	declarations: [],
	imports: [DesignModule],
	exports: [DesignModule],
	providers: [],
	bootstrap: [],
})
export class SharedModule {}

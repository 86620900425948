import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TopnavComponent } from '@tecalisdev/infinity';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationRequestComponent } from './features/registration-request/registration-request.component';
import { StartComponent } from './layouts/start/start.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent, StartComponent, RegistrationRequestComponent],
	imports: [BrowserModule, AppRoutingModule, TopnavComponent, SharedModule],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}

<ng-container *ngIf="step === 1">
	<tec-auth-layout
		img="assets/images/pensumo-logo.svg"
		imgTitle=""
		title="Únete a Pensumo"
		subTitle="Pensumo te ofrece un plan de difelización pionero y único"
	>
		<ng-container body>
			<tec-tile type="banner" styling="default" img="assets/images/media-img.svg"> </tec-tile>
			<div class="auth__body__actions">
				<tec-checkbox
					label="Acepto las cookies que te permiten salir y volver a entrar de la solicitud conservando tus datos"
				></tec-checkbox>
			</div>
			<div class="auth__body__actions">
				Primera capa privacidad con
				<tec-link>anchor text 1</tec-link> +
				<tec-link>anchor text 2</tec-link>
			</div>
			<tec-button size="lg" label="Unirme" (click)="step = 2"></tec-button>
		</ng-container>
	</tec-auth-layout>
</ng-container>
<ng-container *ngIf="step === 2">
	<tec-auth-layout
		img="assets/images/pensumo-logo.svg"
		imgTitle=""
		title="¿En qué canal vendes?"
		subTitle="Solo se recompensarán con Pensumo aquellas compras realizadas a través de tus TPVs. Por ejemplo: no aplicaremos la presente promoción a las ventas que se efectúen a través de otras plataformas o comercios (Amazon, Uber Eats, Glovo...)."
	>
		<ng-container body>
			<tec-item [tagImage]="{ icon: 'icon-tv' }" title="Online" [selected]="isSelectedOption[0]" (click)="selectOption(0)">
				<tec-list-detail>
					<span>Solo vendo a través de mi web</span>
				</tec-list-detail>
			</tec-item>
			<tec-item
				[tagImage]="{ icon: 'icon-store' }"
				title="Físico"
				[selected]="isSelectedOption[1]"
				(click)="selectOption(1)"
			>
				<tec-list-detail>
					<span>Solo vendo en mis locales físicos</span>
				</tec-list-detail>
			</tec-item>
			<tec-item
				[tagImage]="{ icon: 'icon-both' }"
				title="Ambos"
				[selected]="isSelectedOption[2]"
				(click)="selectOption(2)"
			>
				<tec-list-detail>
					<span>Vendo tanto en mis locales físicos como a través de mi web</span>
				</tec-list-detail>
			</tec-item>
			<!-- (buttonClicked)="" -->
			<div class="auth__body__actions"></div>
			<tec-button size="lg" label="Continuar" (clickEvent)="navigate()"></tec-button>
		</ng-container>
	</tec-auth-layout>
</ng-container>

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-start',
	templateUrl: './start.component.html',
	styleUrls: ['./start.component.scss'],
})
export class StartComponent {
	step = 1;
	isSelectedOption = [false, false, false];
	option!: number;
	constructor(private readonly router: Router) {}

	selectOption(option: number): void {
		this.option = option;
		this.isSelectedOption[option] = !this.isSelectedOption[option];
		this.isSelectedOption.filter((boolean, index) =>
			index !== option ? (this.isSelectedOption[index] = false) : boolean
		);
	}

	navigate(): void {
		if (this.isSelectedOption.find((boolean) => boolean)) this.router.navigate(['/registration', this.option]);
	}
}

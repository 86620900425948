<tec-create-layout>
	<tec-item title="Solicitud de alta" [highlight]="true">
		<tec-tag [status]="'success'">{{ registratonType }}</tec-tag>
	</tec-item>

	<tec-stepper-progress [steps]="steps" (clickEvent)="clickEvent($event)"></tec-stepper-progress>
	<ng-container [ngSwitch]="step">
		<ng-container *ngSwitchCase="1">
			<tec-tile [title]="'General'">
				<div class="grid grid-i3">
					<tec-form-group label="País">
						<tec-select
							formControlName="country"
							[placeholder]="'Selecciona el país'"
							[search]="true"
							[options]="[]"
							[hasError]=""
						></tec-select>
					</tec-form-group>
					<tec-form-group label="Nombre del comercio">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Página web">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
				<div class="grid grid-i2">
					<tec-form-group label="Sector al que pertenece">
						<tec-select
							formControlName="country"
							[placeholder]="'Selecciona el sector'"
							[search]="true"
							[options]="[]"
							[hasError]=""
						></tec-select>
					</tec-form-group>
					<tec-form-group label="Descripción de la actividad">
						<tec-textarea
							formControlName="domain"
							[placeholder]="
								'Máximo 400 catacteres. Recuerda que la parte visible serán 210 caracteres, intenta concentrar la información necesaria en esos primeros 210 '
							"
						></tec-textarea>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
				Tipo de negocio
				<ng-container buttons>
					<tec-button>Sociedad</tec-button>
					<tec-button [type]="'secondary'">Autónomo</tec-button>
				</ng-container>
			</tec-tile>
			<tec-tile [title]="'¿Está tu comercio adherido a alguno d elos siguientes programas?'">
				<div class="grid grid-i3">
					<tec-checkbox></tec-checkbox>
				</div>
				<div class="grid grid-i2">
					<tec-checkbox></tec-checkbox>
				</div>
			</tec-tile>
			<tec-tile [title]="'Detalles de la sociedad'">
				<div class="grid grid-i3">
					<tec-form-group label="CIF/NIF">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Razón social">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Email de notificaciones">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
				<div class="grid grid-i3">
					<tec-form-group label="Forma de representación de la sociedad">
						<tec-select
							formControlName="country"
							[placeholder]="'Selecciona la forma de representación'"
							[search]="true"
							[options]="[]"
							[hasError]=""
						></tec-select>
					</tec-form-group>
				</div>
				REPRESENTANTE
				<div class="grid grid-i3">
					<tec-form-group label="Nombre del comercio">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="DNI">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Email de contacto">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
			</tec-tile>
			<tec-tile
				[title]="'Escritura'"
				[subtitle]="
					'Escritura notarial que acredite que el firmante tiene los poderes suficientes para representar a la sociedad y firmar el contrato con Pensumo'
				"
			>
				<tec-form-group>
					<tec-upload [size]="'default'" [acceptedFileTypes]="['.jpg', '.jpeg', '.png']"></tec-upload>
				</tec-form-group>
			</tec-tile>
			<tec-tile [title]="'Mandato SEPA'">
				<div class="grid grid-i3">
					<tec-form-group label="Nombre del deudor">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Dirección del deudor">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Código Postal">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
				<div class="grid grid-i3">
					<tec-form-group label="Población">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Provincia">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="País del deudor">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
				<div class="grid grid-i3">
					<tec-form-group label="SWIFT BIC">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="IBAN">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
					<tec-form-group label="Localidad de la firma">
						<tec-input formControlName="domain" [hasError]=""></tec-input>
						<span *ngIf="" class="form-group__error-message">{{ 'ERRORS.DOMAIN' }}</span>
					</tec-form-group>
				</div>
			</tec-tile>
		</ng-container>
	</ng-container>
	<tec-button [label]="'Salir'" [type]="'secondary'" (click)="buttonAction('BACK')"></tec-button>
	<tec-button [label]="'Continuar'" [type]="'default'" (click)="buttonAction('SAVE')"></tec-button>
</tec-create-layout>
